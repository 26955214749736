
import { Vue, Component, Prop } from 'vue-property-decorator'
import $app from '@/plugins/modules'
import { Access } from '@/common/enums'

@Component
export default class CustomerTabs extends Vue {
  @Prop({ default: '' })
  readonly tab: string | undefined;

  get hasAuditAccess(): boolean {
    return $app.access(Access.SecurityManager)
  }

  get tabValue(): string | undefined {
    return this.tab ? this.tab : 'tabGeneral';
  }

  set tabValue(value: string | undefined) {
    this.$emit('input', value)
  }
}
